'use client';

import PropTypes from 'prop-types';

import {useBoolean} from 'src/hooks/use-boolean';
import {useResponsive} from 'src/hooks/use-responsive';

import RecipeReturnToFormInfo from "src/components/recipe/recipe-return-to-form-info";

import Main from './main';
import Header from './header';
import Footer from "./footer";
import NavTop from "./nav-top";
import NavMobile from './nav-mobile';
import SearchDialog from "./search/search-dialog";

// ----------------------------------------------------------------------

export default function FofooClientLayout({children, topMenuData, mobileMenuData, footerMenuData}) {

  const mdUp = useResponsive('up', 'md', null, {defaultMatches: true});

  const nav = useBoolean();

  const renderHorizontal = <NavTop data={topMenuData}/>;

  const renderNavMobile = <NavMobile openNav={nav.value} onCloseNav={nav.onFalse} navData={mobileMenuData}/>;

  return (
    <>
      <Header onOpenNav={nav.onTrue}/>

      <SearchDialog/>
      <RecipeReturnToFormInfo/>

      {mdUp ? renderHorizontal : renderNavMobile}

      <Main>
        {children}
      </Main>

      <Footer menuData={footerMenuData}/>
    </>
  );
}

FofooClientLayout.propTypes = {
  children: PropTypes.node,
  topMenuData: PropTypes.object,
  mobileMenuData: PropTypes.object,
  footerMenuData: PropTypes.object
};
