/* eslint-disable import/no-cycle */
import PropTypes from 'prop-types';

import {Stack} from "@mui/material";

import Renderer from "./renderer";

function MenuContainer({item}) {
  return <div key={`menu-container-${item.id}`}>
    <Stack key={`menu-container-stack-${item.id}`} direction={item.direction} spacing={item.spacing}>
      {item.items?.map(renderItem => <Renderer key={`renderer-menu-container-item-${renderItem.id}`} item={renderItem}/>)}
    </Stack>
  </div>;
}

MenuContainer.propTypes = {
  item: PropTypes.object
};

export default MenuContainer;
