import { memo } from 'react';

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import {alpha, styled} from '@mui/material/styles';
import ListItemButton from "@mui/material/ListItemButton";

import { useNavData } from './config-navigation';
import {RouterLink} from "../../routes/components";

// ----------------------------------------------------------------------

function NavHeader() {
  const {headerNavData} = useNavData();

  const StyledNavItem = styled(ListItemButton, {
    shouldForwardProp: (prop) => prop !== 'active',
  })(({ open, active, theme }) => {
    const opened = open && !active;

    return {
      ...theme.typography.body2,
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightMedium,
      height: 48,
      ...(active && {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightSemiBold,
        backgroundColor: alpha(theme.palette.primary.main, 0.08),
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.main, 0.16),
        },
      }),
      ...(opened && {
        backgroundColor: theme.palette.action.hover,
      }),
    };
  });

  return (
    <>
      {headerNavData.map((navItem) => (
        <Link component={RouterLink} href={navItem.path} color="inherit" underline="none" key={navItem.path}>
          <StyledNavItem>
            <Box component="span" sx={{ flexGrow: 1 }}>
              {navItem.title}
            </Box>
          </StyledNavItem>
        </Link>
      ))}
    </>
  );
}


export default memo(NavHeader);
