import {useRef, useState} from 'react';

import {fetchRecipeList} from 'src/services/recipe/list-api';

export function useRecipeListApi(defaultRecipes = []) {
  const isLoading = useRef(false);
  const [recipeList, setRecipeList] = useState(defaultRecipes);

  const loadRecipeList = async (data) => {
    if (isLoading.current) {
      return;
    }

    isLoading.current = true;
    const listResponse = await fetchRecipeList(data);
    setRecipeList(listResponse);
    isLoading.current = false;
  }

  return {
    recipeList,
    setRecipeList,
    loadRecipeList,
    isLoading
  };
}
