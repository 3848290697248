/* eslint-disable import/no-cycle */

import PropTypes from "prop-types";

import {Stack} from "@mui/material";

import Renderer from "./renderer";

function MenuContainer({item, menuId}) {
  return <div key={`mobile-menu-container-${item.id}`}>
    <Stack key={`mobile-menu-container-stack-${item.id}`} direction={item.direction} spacing={item.spacing}>
      {item.items?.map(renderItem => <Renderer key={`renderer-mobile-menu-container-item-${renderItem.id}`} item={renderItem} menuId={menuId}/>)}
    </Stack>
  </div>;
}

MenuContainer.propTypes = {
  item: PropTypes.object,
  menuId: PropTypes.string
};

export default MenuContainer;
