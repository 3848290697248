import PropTypes from "prop-types";

import Image from "../../image";

function MenuImage({item}) {
  const menuImage = <Image key={`mobile-menu-image-${item.id}`} alt={item.title} src={item.imageUrl} />

  return menuImage;
}

MenuImage.propTypes = {
  item: PropTypes.object
};

export default MenuImage;
