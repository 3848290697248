import PropTypes from "prop-types";

import {styled} from "@mui/material/styles";

import Renderer from "./renderer";

export const StyledMenuContainer = styled('div')(() => ({
  color: '#637381',
  display: 'flex',
  justifyContent: 'end'
}));

function Menu({id, items}) {
  return <StyledMenuContainer id={id}>
    {items?.map(item => <Renderer key={`renderer-menu-item-${item.id}`} item={item}/>)}
    </StyledMenuContainer>
}

Menu.propTypes = {
  id: PropTypes.string,
  items: PropTypes.array
};

export default Menu;
