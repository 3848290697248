/* eslint-disable import/no-cycle */

import PropTypes from "prop-types";
import React, {useRef, useState, useCallback} from "react";

import Popover from "@mui/material/Popover";

import {hideScroll} from "src/theme/css";

import NavItem from "./nav-item";
import Renderer from "./renderer";

function MenuDropdown({item}) {
  const navRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleOpenMenu = useCallback(() => {
    if (item.items) {
      setOpenMenu(true);
    }
  }, [item.items]);

  const handleCloseMenu = useCallback(() => {
    setOpenMenu(false);
  }, []);

  let popoverContent = '';
  if (item.items) {
    popoverContent = <Popover
      disableScrollLock
      open={openMenu}
      anchorEl={navRef.current}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      slotProps={{
        paper: {
          onMouseEnter: handleOpenMenu,
          onMouseLeave: handleCloseMenu,
          sx: {
            ...hideScroll.y,
            p: 3,
            mt: -1,
            maxWidth: (theme) => theme.breakpoints.values.lg,
            ...(openMenu && {
              pointerEvents: 'auto',
            }),
            ...(item.style === 'full_width' && {
              width: '100%',
              maxWidth: '100%',
              borderRadius: 0,
              left: '0 !important',
              mt: 0,
              px: {lg: 5},
            }),
          },
        },
      }}
      sx={{
        pointerEvents: 'none',
      }}
    >
      {item.items?.map(renderItem => <Renderer key={`renderer-menu-dropdown-item-${renderItem.id}`} item={renderItem}/>)}
    </Popover>;
  }

  return <div key={`menu-dropdown-${item.id}`}>
    <NavItem
      key={`menu-dropdown-item-${item.id}`}
      ref={navRef}
      open={openMenu}
      onMouseEnter={handleOpenMenu}
      onMouseLeave={handleCloseMenu}
      title={item.title}
      path=""
      hasChild={!!item.items}
      sx={{
        minHeight: {sm: 64},
      }}
    />
    {popoverContent}
  </div>;
}

MenuDropdown.propTypes = {
  item: PropTypes.object
};

export default MenuDropdown;
