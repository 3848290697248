import PropTypes from 'prop-types';

import {Typography} from "@mui/material";

import MenuLink from "./menu-link";
import MenuLabel from "./menu-label";
import MenuImage from "./menu-image";
// eslint-disable-next-line import/no-cycle
import MenuDropdown from "./menu-dropdown";
// eslint-disable-next-line import/no-cycle
import MenuContainer from "./menu-container";

function Renderer({item, menuId}) {
  const typeName =  item.__typename;

  switch (typeName) {
    case 'object_MenuContainer':
      return <MenuContainer item={item} menuId={menuId}/>
    case 'object_MenuLabel':
      return <MenuLabel item={item} menuId={menuId}/>
    case 'object_MenuLink':
      return <MenuLink item={item} menuId={menuId}/>
    case 'object_MenuImage':
      return <MenuImage item={item} menuId={menuId}/>
    case 'object_MenuDropdown':
      return <MenuDropdown item={item} menuId={menuId}/>
    default:
      return <Typography color="error.main">{`Type: ${typeName} menu renderer not found`}</Typography>;
  }
}

Renderer.propTypes = {
  item: PropTypes.object,
  menuId: PropTypes.string
};

export default Renderer;
