import PropTypes from 'prop-types';
import {memo, useEffect} from 'react';

import Box from '@mui/material/Box';
import Stack from "@mui/material/Stack";
import Drawer from '@mui/material/Drawer';
import IconButton from "@mui/material/IconButton";

import {usePathname} from 'src/routes/hooks';

import Logo from 'src/components/logo';
import Scrollbar from 'src/components/scrollbar';
import {MobileMenu} from "src/components/fofoo-menu";

import {NAV} from '../config-layout';
import Iconify from "../../components/iconify";
import NavToggleButton from '../common/nav-toggle-button';

// ----------------------------------------------------------------------

function NavMobile({navData, openNav, onCloseNav}) {

  const pathname = usePathname();

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        ml: 1,
        mr: 1,
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack direction="row" alignItems="flex-start" justifyContent="space-between" px={1} py={1.5} sx={{mb: 1}}>
        <Logo/>

        <IconButton onClick={onCloseNav} sx={{p: 0}}>
          <Iconify icon="material-symbols:close" width={32}/>
        </IconButton>
      </Stack>

      <MobileMenu id="fofoo-mobile-menu" items={navData.items}/>

      <Box sx={{flexGrow: 1}}/>
    </Scrollbar>
  );

  return (
    <Box
      sx={{
        flexShrink: {lg: 0},
        width: {lg: NAV.W_VERTICAL},
      }}
    >
      <NavToggleButton/>

      <Drawer
        open={openNav}
        onClose={onCloseNav}
        PaperProps={{
          sx: {
            width: NAV.W_VERTICAL,
          },
        }}
      >
        {renderContent}
      </Drawer>
    </Box>
  );
}

NavMobile.propTypes = {
  navData: PropTypes.object,
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func
};

export default memo(NavMobile);
