import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {useTheme} from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

import {useOffSetTop} from 'src/hooks/use-off-set-top';
import {useResponsive} from 'src/hooks/use-responsive';

import {bgBlur} from 'src/theme/css';

import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';

import NavHeader from './nav-header';
import {HEADER} from '../config-layout';
import Searchbar from './search/search-bar';
import AccountPopover from '../common/account-popover';

// ----------------------------------------------------------------------

export default function Header({onOpenNav}) {
  const theme = useTheme();

  const mdUp = useResponsive('up', 'md', null, {defaultMatches: true});

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset;

  const renderContent = (
    <>
      {!mdUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg"/>
        </IconButton>
      )}

      <Logo sx={{mr: 2.5}}/>

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{xs: 0.5, sm: 1}}
      >
        <Searchbar mdUp={mdUp}/>

        {mdUp && (
        <NavHeader/>
        )}

        {/* <LanguagePopover/> */}

        {/* <NotificationsPopover/> */}

        {/* <ContactsPopover/> */}

        <AccountPopover/>
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(mdUp && {
          width: 1,
          height: HEADER.H_DESKTOP_OFFSET,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          bgcolor: 'background.default',
          borderBottom: `dashed 1px ${theme.palette.divider}`,
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: {lg: 5},
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
