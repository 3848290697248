/* eslint-disable import/no-cycle */

import PropTypes from "prop-types";
import React, {useRef, useState, useEffect, useCallback} from "react";

import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";

import {usePathname} from "src/routes/hooks";

import NavItem from "./nav-item";
import Renderer from "./renderer";
import Iconify from "../../iconify";

function MenuDropdown({item, menuId}) {
  const pathname = usePathname();

  const navRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);

  const [rectWidth, setRectWidth] = useState(0);

  useEffect(() => {
    if (openMenu) {
      handleCloseMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    const element = document.getElementById(menuId);

    if (element) {
      const clientRect = element?.getBoundingClientRect();

      setRectWidth(clientRect.width);
    }
  }, [menuId]);

  const handleOpenMenu = useCallback(() => {
    if (item.items) {
      setOpenMenu(true);
    }
  }, [item.items]);

  const handleCloseMenu = useCallback(() => {
    setOpenMenu(false);
  }, []);

  let drawerContent = '';
  if (item.items) {
    drawerContent = <Drawer
      open={openMenu}
      onClose={handleCloseMenu}
      slotProps={{
        backdrop: { invisible: true },
      }}
      PaperProps={{
        sx: {
          width: rectWidth - 8,
        },
      }}
    >
      <Stack direction="row" alignItems="flex-start" justifyContent="flex-end" px={1} py={1.5}>
        <IconButton onClick={handleCloseMenu}>
          <Iconify icon="material-symbols:close" width={32} />
        </IconButton>
      </Stack>
      <div>
        {item.items?.map(renderItem => <Renderer key={`renderer-mobile-menu-dropdown-item-${renderItem.id}`} item={renderItem}/>)}
      </div>
    </Drawer>;
  }

  return <div key={`mobile-menu-dropdown-${item.id}`}>
    <NavItem
      key={`mobile-menu-dropdown-item-${item.id}`}
      ref={navRef}
      open={openMenu}
      onMouseEnter={handleOpenMenu}
      onMouseLeave={handleCloseMenu}
      title={item.title}
      path=""
      hasChild={!!item.items}
    />
    {drawerContent}
  </div>;
}

MenuDropdown.propTypes = {
  item: PropTypes.object,
  menuId: PropTypes.string
};

export default MenuDropdown;
