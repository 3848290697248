import PropTypes from "prop-types";

import NavItem from "./nav-item";

function MenuLink({item}) {
  return <NavItem key={`mobile-menu-link-${item.id}`} path={item.url} externalLink={item.redirectType === 'new_tab'} title={item.title} />;
}

MenuLink.propTypes = {
  item: PropTypes.object
};

export default MenuLink;
