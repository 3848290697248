// eslint-disable-next-line import/no-extraneous-dependencies
import debounce from 'lodash.debounce';
import { useRef, useCallback } from "react";

export const useDebouncedCallback = (callback, delay, options) => {
  const callbackRef = useRef()
  callbackRef.current = callback;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(debounce(
    (...args) => callbackRef.current(...args),
    delay,
    options
  ), []);
};
