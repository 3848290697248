import { m } from 'framer-motion';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useAuthContext } from 'src/auth/hooks';

import { varHover } from 'src/components/animate';
import { useSnackbar } from 'src/components/snackbar';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

const MENU_LOGGED_IN_OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
  {
    label: 'Settings',
    linkTo: paths.dashboard.user.account,
  },
];

const MENU_LOGGED_OUT_OPTIONS = [
  {
    label: 'Register',
    linkTo: paths.auth.token.register,
  },
  {
    label: 'Sign in',
    linkTo: paths.auth.token.login,
  }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const router = useRouter();

  const { logout, user } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const popover = usePopover();

  const handleLogout = async () => {
    try {
      await logout();
      popover.onClose();
      router.replace('/');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleClickItem = (path) => {
    popover.onClose();
    router.push(path);
  };

  const getLoggedInPopover = () => (
      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{my: 1.5, px: 2.5}}>
          <Typography variant="subtitle2" noWrap>
            {user?.name}
          </Typography>
          <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{borderStyle: 'dashed'}}/>

        <Stack sx={{p: 1}}>
          {MENU_LOGGED_IN_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{borderStyle: 'dashed'}}/>

        <MenuItem onClick={handleLogout} sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}>
          Logout
        </MenuItem>
      </CustomPopover>
    )

  const getLoggedOutPopover = () => (
      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{my: 1.5, px: 2.5}}>
          <Typography variant="subtitle2" noWrap>
            Welcome in KuliGuli!
          </Typography>
        </Box>

        <Divider sx={{borderStyle: 'dashed'}}/>

        <Stack sx={{p: 1}}>
          {MENU_LOGGED_OUT_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </CustomPopover>
    )

  const getPopover = () => {
    if (user) {
      return getLoggedInPopover();
    }
      return getLoggedOutPopover();

  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={user?.photoUrl}
          alt={user?.name}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {user?.name?.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      {getPopover()}
    </>
  );
}
