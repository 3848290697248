'use client';

import merge from 'lodash/merge';
import {
  pl as plPLAdapter,
  enUS as enUSAdapter,
} from 'date-fns/locale';

// core
import {
  enUS as enUSCore,
  plPL as plPLCore
} from '@mui/material/locale';
// data-grid
import {
  enUS as enUSDataGrid,
  plPL as plPLDataGrid
} from '@mui/x-data-grid';
// date-pickers
import {
  enUS as enUSDate,
  plPL as plPLDate,
} from '@mui/x-date-pickers/locales';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:gb-nir',
  },
  {
    label: 'Polski',
    value: 'pl',
    systemValue: merge(plPLDate, plPLDataGrid, plPLCore),
    adapterLocale: plPLAdapter,
    icon: 'flagpack:pl',
  },
];

export const defaultLang = allLangs[0]; // English

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
