import _ from 'lodash';

import {getParamValue} from "src/utils/url-data-params";

import {FILTERS_CONFIG} from "src/services/recipe/list-api";

export default class FilterData {
  filterFields = {}

  constructor(initialFilterFields = null) {
    if (initialFilterFields !== null) {
      this.filterFields = initialFilterFields;
    }
  }

  getFilterFields() {
    return this.filterFields;
  }

  getFilterFieldsAsArray() {
    return Object.entries(this.getFilterFields());
  }

  getFilterField(name) {
    return this.filterFields[name] ?? null;
  }

  setFilterFields(filterFields) {
    this.filterFields = filterFields;
    return this;
  }

  hasFilterFields() {
    let hasFilterFields = typeof this.getFilterFields() === 'object' && Object.keys(this.getFilterFields()).length > 0;
    // eslint-disable-next-line no-bitwise
    hasFilterFields &= Object.values(this.getFilterFields()).filter(value => !_.isEmpty(value)).length > 0;

    return hasFilterFields;
  }

  canReset() {
    return typeof this.getFilterFields() === 'object' && Object.keys(this.getFilterFields()).filter(key => key !== 'search').length > 0;
  }

  // getPreparedFilterData
  getDataForQuery() {
    if (!this.hasFilterFields()) {
      return {};
    }

    const preparedFilterData = {};

    Object.entries(this.getFilterFields()).forEach(([filterKey, filterData]) => {
      const filterConfig = FILTERS_CONFIG.find(config => config.code === filterKey);
      if (!filterConfig) {
        return;
      }

      if (!filterData || filterData.length <= 0) {
        return;
      }

      let preparedValue = null;
      if (Array.isArray(filterData)) {
        preparedValue = [];
        filterData.forEach(filterDataElement => {
          if (typeof filterDataElement === 'object') {
            preparedValue.push(filterDataElement.code);
          } else {
            preparedValue.push(filterDataElement);
          }
        });
      } else if (typeof filterData === 'object') {
          preparedValue = filterData.code;
        } else {
          preparedValue = filterData;
        }

      preparedFilterData[filterConfig.queryField] = preparedValue;
    });

    return preparedFilterData;
  }

  // getFiltersForUrl
  getDataForUrl() {
    if (!this.hasFilterFields()) {
      return null;
    }

    const preparedFiltersForUrl = {};

    Object.entries(this.getFilterFields()).forEach(([filterKey, filterData]) => {
      const filterConfig = FILTERS_CONFIG.find(config => config.code === filterKey);
      if (!filterConfig || (filterConfig.hideInUrl ?? false)) {
        return;
      }

      if (!filterData || filterData.length <= 0) {
        return;
      }

      let preparedFilterData = null;
      if (Array.isArray(filterData)) {
        preparedFilterData = [];
        filterData.forEach(filterDataElement => {
          if (typeof filterDataElement === 'object') {
            preparedFilterData.push(filterDataElement.code);
          } else {
            preparedFilterData.push(filterDataElement);
          }
        });
        preparedFilterData = preparedFilterData.join(',');
      } else if (typeof filterData === 'object') {
          preparedFilterData = filterData.code;
        } else {
          preparedFilterData = filterData;
        }

      if (!preparedFilterData) {
        return;
      }

      preparedFiltersForUrl[filterKey] = preparedFilterData;
    });

    return preparedFiltersForUrl;
  }

  // getFiltersFromUrlParams
  async fillFiltersFromUrlParams(params) {
    const filterData = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const filterConfig of FILTERS_CONFIG) {
      const paramValue = getParamValue(params, filterConfig.code);
      if (paramValue === null) {
        // eslint-disable-next-line no-continue
        continue;
      }

      let options = {};

      if (filterConfig.optionsProvider) {
        const optionsProviderParams = [];
        if (filterConfig.optionsProviderPassOptionIds) {
          optionsProviderParams.push(paramValue);
        }

        // eslint-disable-next-line no-await-in-loop
        options = await filterConfig.optionsProvider.call(this, optionsProviderParams);
      }

      let preparedParamValue = null;
      if (filterConfig.type === 'multiselect') {
        preparedParamValue = paramValue?.includes(',')
          ? paramValue.split(",").map(param => ({code: param, label: options[param]}))
          : [{code: paramValue, label: options[paramValue]}];
      } else if (filterConfig.type === 'select') {
        preparedParamValue = {code: paramValue, label: options[paramValue]};
      } else {
        preparedParamValue = paramValue;
      }

      filterData[filterConfig.code] = preparedParamValue;
    }

    this.setFilterFields(filterData);
  };

}
