import React from "react";

import {Box, Dialog} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import useSearchDialog from "src/hooks/search/use-search-dialog";

import Iconify from "src/components/iconify";
import KuliguliSearchInput from "src/components/input/kuliguli-search-input";

import SearchDialogResults from "./search-dialog/results";

export default function SearchDialog() {
  const {isOpen, setIsOpen, query, setQuery} = useSearchDialog();

  const handleClose = () => {
    setIsOpen(false);
    setQuery('');
  };

  const handleChange = (e) => {
    setQuery(e.target.value);
  }

  return (
    <Dialog fullScreen open={isOpen} onClose={handleClose}>
      <Toolbar>
        <IconButton color="inherit" edge="end" onClick={handleClose}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      </Toolbar>

      <Box sx={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        mt: 1
      }}>
        <Box sx={{
          width: {xs: '90%', md: 700},
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Typography variant="h3" sx={{ flex: 1, mb: 3 }} color="primary.main">
            Znajdź to, czego szukasz w KuliGuli
          </Typography>
          <KuliguliSearchInput
            inputRef={input => input && input.focus()}
            fullWidth
            id="search-dialog-search-input"
            value={query}
            onChange={handleChange}
          />
        </Box>
      </Box>
      <SearchDialogResults/>
    </Dialog>
  );
}
