import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import useSearchDialog from "src/hooks/search/use-search-dialog";

import RecipeTile from 'src/components/recipe/recipe-tile';

export default function SearchDialogList({items}) {
  const {setIsOpen} = useSearchDialog();
  const onTileClick = () => {
    setIsOpen(false);
  };

  return (
    <Box
        gap={3}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
        }}
      >
        {items.map((item) => (
          <RecipeTile
            key={item.pimcoreRecipeId}
            recipe={item}
            onTileClick={onTileClick}
          />
        ))}
      </Box>
  );
}

SearchDialogList.propTypes = {
  items: PropTypes.array
};
