'use client';

import {useRef} from "react";
import {useSnackbar, closeSnackbar} from "notistack";
import {useRouter, usePathname} from "next/navigation";

import Button from "@mui/material/Button";

import {paths} from "src/routes/paths";

import {usePersistForm} from "src/hooks/use-persist-form";

import {useAuthContext} from "src/auth/hooks";

const FORM_DATA_KEY = 'recipe-new-form';
const RETURN_TO_FORM_INFO_HIDE_KEY = 'return-ro-recipe-form-info-hide';

export default function RecipeReturnToFormInfo() {
  const {enqueueSnackbar} = useSnackbar();
  const router = useRouter()
  const snackBar = useRef(null);
  const {authenticated} = useAuthContext();
  const pathname = usePathname();

  const {
    load: loadPersistedData
  } = usePersistForm({localStorageKey: FORM_DATA_KEY});

  if (!authenticated) {
    return;
  }

  if (pathname.replace(/\/$/, "") === paths.recipe.add) {
    return;
  }

  if (snackBar.current === null && typeof window !== 'undefined') {
    const hideInfo = localStorage.getItem(RETURN_TO_FORM_INFO_HIDE_KEY);
    if (hideInfo) {
      snackBar.current = false;
      return;
    }

    const persistedFormData = loadPersistedData();
    if (persistedFormData?.formData?.name) {
      snackBar.current = enqueueSnackbar('Masz rozpoczęte dodawanie nowego przepisu. Czy chcesz wrócić do formularza?', {
        variant: 'info',
        autoHideDuration: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        action: (key) => (
          <>
            <Button
              size="small"
              color="info"
              onClick={() => {
                router.push(paths.recipe.add);
                snackBar.current = false;
                closeSnackbar(key);
              }}
            >
              Wróć do formularza
            </Button>

            <Button size="small" color="inherit" onClick={() => {
              closeSnackbar(key);
              if (typeof window !== 'undefined') {
                localStorage.setItem(RETURN_TO_FORM_INFO_HIDE_KEY, 'true');
              }
            }}>
              Zamknij
            </Button>
          </>
        ),
      });
    } else {
      snackBar.current = false;
    }
  }
}
