export const usePersistForm = ({localStorageKey}) => {
  const save = (formData, ttl) => {
    if (typeof window === 'undefined') {
      return;
    }

    localStorage.setItem(localStorageKey, JSON.stringify({
      formData,
      expiry: Date.now() + ttl
    }));
  }

  const load = () => {
    if (typeof window === 'undefined') {
      return null;
    }

    const data = localStorage.getItem(localStorageKey);
    if (data) {
      const parsedData = JSON.parse(data);
      if (Date.now() > parsedData.expiry) {
        localStorage.removeItem(localStorageKey);
        return null;
      }

      return parsedData;
    }

    return null;
  }

  const clear = () => {
    if (typeof window === 'undefined') {
      return;
    }

    localStorage.removeItem(localStorageKey);
  }

  return {
    save,
    load,
    clear
  }
};
