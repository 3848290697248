import {memo} from 'react';
import {v4 as uuidv4} from 'uuid';
import PropTypes from 'prop-types';

import useSearchDialog from "src/hooks/search/use-search-dialog";

import KuliguliSearchInput from "src/components/input/kuliguli-search-input";


// ----------------------------------------------------------------------

function SearchBar({mdUp}) {
  const {setIsOpen} = useSearchDialog();

  const openSearchDialog = () => {
    setIsOpen(true);
  }

  return (<KuliguliSearchInput
    isMobileVariant={!mdUp}
    id="header-search-input"
    size="small"
    onFocus={openSearchDialog}
    key={uuidv4()}
  />);
}

SearchBar.propTypes = {
  mdUp: PropTypes.bool
}

export default memo(SearchBar);
