import React, {memo} from 'react';
import PropTypes from 'prop-types';

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {useTheme} from "@mui/material/styles";

import {HorizontalMenu} from "src/components/fofoo-menu";

import {bgBlur} from "../../theme/css";
import {HEADER} from "../config-layout";
import HeaderShadow from "../common/header-shadow";

// ----------------------------------------------------------------------

function NavTop({data}) {
  const theme = useTheme();

  return (
    <AppBar
      component="div"
      sx={{
        top: HEADER.H_DESKTOP_OFFSET,
      }}
    >
      <Toolbar
        sx={{
          ...bgBlur({
            color: theme.palette.background.default,
          }),
          px: { lg: 5 },
        }}
      >
        <HorizontalMenu id="fofoo-top-menu" items={data.items} />
      </Toolbar>
      <HeaderShadow/>
    </AppBar>
  );
}

NavTop.propTypes = {
  data: PropTypes.object
};

export default memo(NavTop);
