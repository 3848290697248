import {HOST_API} from 'src/config-global';

const RECIPE_LIST_API_URI = '/api/recipe/list';

export async function fetchRecipeList(data) {
  const response = await fetch(`${HOST_API}${RECIPE_LIST_API_URI}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    cache: 'no-store'
  });
  return response.json();
}

async function fetchPimcoreOptionsMap(uri, params) {
  let url = `${HOST_API}${uri}`;

  params?.forEach(param => {
    url += `/${param}`;
  })

  const response = await fetch(url);
  const responseJson = await response.json();

  const optionsMap = {};

  responseJson.list?.forEach(element => {
    optionsMap[element.value] = element.label;
  });

  return optionsMap;
}

export const DEFAULT_SIZE = 6;

export const DEFAULT_SORT = 'latest';
export const SORT_OPTIONS = [
  {'value': 'latest', 'label': "Najnowsze", 'query': [{'createdAt': 'desc'}]},
  {'value': 'oldest', 'label': "Najstarsze", 'query': [{'createdAt': 'asc'}]},
];

export const FILTERS_CONFIG = [
  {
    code: "timeConsuming",
    label: "Czas przygotowania",
    queryField: "timeConsumingCode",
    type: "select",
    optionsProvider: fetchPimcoreOptionsMap.bind(this, '/api/selectOption/list/recipe/TimeConsuming')
  },
  {
    code: "forHowManyPerson",
    label: "Dla ilu osób",
    queryField: "forHowManyPersonCode",
    type: "select",
    optionsProvider: fetchPimcoreOptionsMap.bind(this, '/api/selectOption/list/recipe/ForHowManyPerson')
  },
  {
    code: "recipeCategories",
    label: "Kategorie",
    queryField: "recipeCategories",
    type: "multiselect",
    optionsProvider: fetchPimcoreOptionsMap.bind(this, '/api/recipeCategory/list')
  },
  {
    code: "ingredients",
    label: "Składniki",
    queryField: "ingredients",
    type: "multiselect",
    optionsProvider: fetchPimcoreOptionsMap.bind(this, '/api/ingredient/list'),
    optionsProviderPassOptionIds: true
  },
  {
    code: "search",
    label: "Wyszukaj",
    queryField: "search",
    type: "input",
    optionsProvider: null
  }
];

export function getPreparedSortData(sortBy) {
  if (!sortBy) {
    return null;
  }

  const sortOption = SORT_OPTIONS.find(option => option.value === sortBy);
  if (!sortOption) {
    return null;
  }

  return sortOption.query;
}

export function getAvailableFilterBuckets(aggregations) {
  if (!aggregations) {
    return {};
  }

  const availableFilters = {};

  Object.entries(aggregations).forEach(([aggregationKey, buckets]) => {
    const filterConfig = FILTERS_CONFIG.find(config => config.queryField === aggregationKey);
    if (!filterConfig) {
      return;
    }

    const filterOptions = [];
    buckets.forEach((entry) => {
      if (!entry.label) {
        return;
      }

      filterOptions.push({
        code: entry.key,
        label: entry.label
      });
    });

    if (filterOptions.length > 0) {
      availableFilters[filterConfig.code] = filterOptions;
    }
  });

  return availableFilters;
}
