import {useRef} from "react";

import Alert from "@mui/material/Alert";
import {Box, Button} from "@mui/material";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import useSearchDialog from "src/hooks/search/use-search-dialog";
import {useNonInitialEffect} from "src/hooks/use-non-initial-effect";
import {useDebouncedCallback} from "src/hooks/use-debounced-callback";
import {useRecipeListApi} from "src/hooks/recipe/list/use-recipe-list-api";

import FilterData from "src/models/recipe/list/filter-data";

import SearchDialogList from "./list";
import {paths} from "../../../../routes/paths";

export default function SearchDialogResults() {
  const MAX_SEARCH_DIALOG_RESULTS = 6;
  const MIN_QUERY_LENGTH = 3;

  const {query} = useSearchDialog();
  const {recipeList, setRecipeList, loadRecipeList} = useRecipeListApi();
  const isLoaded = useRef(false);

  const reloadRecipeList = async (input) => {
    const filters = new FilterData({search: input});
    const filterData = filters.getDataForQuery();

    await loadRecipeList({
      size: MAX_SEARCH_DIALOG_RESULTS,
      filters: filterData
    });

    isLoaded.current = true;
  };

  const debouncedReloadRecipeList = useDebouncedCallback((input) => {
    reloadRecipeList(input);
  }, 800, {leading: true})

  useNonInitialEffect(() => {
    if (query?.length >= MIN_QUERY_LENGTH) {
      debouncedReloadRecipeList(query);
    } else {
      debouncedReloadRecipeList.cancel();
      setRecipeList([]);
      isLoaded.current = false;
    }

  }, [query]);

  const showResultCount = isLoaded.current;

  const renderResultCount = () => {
    if (!showResultCount) {
      return '';
    }

    if (recipeList?.totalResults > 0) {
      return <Typography variant="body2"
                         sx={{mb: 2}}>{`Znaleziono ${recipeList?.totalResults ?? 0} rzeczy`}</Typography>;
    } 
      return <Alert severity="warning">
        {`Nie odnaleziono wyników wyszukiwania dla frazy: "${query}"`}
      </Alert>;
    
  }

  const renderAdvancedSearchButton = () => {
    if (!showResultCount || recipeList?.totalResults <= MAX_SEARCH_DIALOG_RESULTS) {
      return '';
    }

    return <Button sx={{mt: 3, mb: 4}} size='large' variant='outlined' color='primary'
                   href={paths.recipe.browseWithParams({search: query})}>Zobacz więcej wyników</Button>
  }

  return (
    <Container sx={{
      mt: 4
    }}>
      {renderResultCount()}
      <SearchDialogList items={recipeList?.items ?? []}/>
      <Box sx={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        mt: 1
      }}>
        {renderAdvancedSearchButton()}
      </Box>
    </Container>
  )
}
