import PropTypes from "prop-types";

import NavItem from "./nav-item";

function MenuLabel({item}) {
  return <NavItem key={`mobile-menu-label-${item.id}`} sx={{fontWeight: (item.style ? 'bold' : 'normal')}} title={item.title} />;
}

MenuLabel.propTypes = {
  item: PropTypes.object
};

export default MenuLabel;
